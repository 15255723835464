import React, { useCallback, useState } from "react";
import { Button, Modal, Form, Select, notification, InputNumber, ConfigProvider, Input, ColorPicker, Checkbox } from 'antd';
import { SettingOutlined,PlusOutlined } from '@ant-design/icons';
import { api } from '../../const';
import type { FormProps, InputNumberProps } from 'antd';

type FieldType = {
    serverrole?: string;
    siteroles?: any;
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

interface RolesModalProps {
    rd?: {
        id: number;
        name: string;
        access: any;
        admin: boolean;
        color: string;
    };
    updateData: any;
    options: any;
    newrole?: boolean;
}

const RolesEditModal: React.FC<RolesModalProps> = ({ rd, updateData, options, newrole }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [api1, contextHolder] = notification.useNotification({
        stack: { threshold: 1 },
    });
    const [nd, snd] = useState({
        id: rd?.id || 1,
        name: rd?.name || 'Название роли',
        access: rd?.access || [],
        admin: rd?.admin || false,
        color: rd?.color || '#ffffff',
    });

    const openNotification = () => {
        api1.success({
            message: `${newrole && 'Данные отправлены' || 'Данные успешно обновлены'}`,
            description: `Роль: ${nd.name}`,
            placement: 'bottom',
        });
    };

    const sendNewData = useCallback(async () => {
        try {
            const newData = {
                newrole: newrole, id: newrole ? nd.id : rd?.id, nd
            };
            const res = await api.post('/api/admin/roles/edit', newData, { withCredentials: true });
            if (res.status === 200) {
                openNotification();
                updateData();
                setModalOpen(false);
            }
        } catch (err) {
            console.log(err);
        }
    }, [newrole, nd, openNotification, updateData]);

    const onFinish = (values: any) => {
        sendNewData();
    };

    const handleChange = (key: string, value: any) => {
        snd(prevState => ({ ...prevState, [key]: value }));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            {contextHolder}
            <Button type="default" style={{marginRight: 2}} onClick={() => setModalOpen(true)}>{newrole ? (<span>Добавить <PlusOutlined /></span>):(<span>Изменить <SettingOutlined /></span>)}</Button>
            <Modal
                title="Изменение ролей"
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
            >
                <Form
                    name="roles-edit-modal-form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={nd}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="ID"
                        name="id"
                        rules={[{ required: true, message: 'Пожалуйста, введи сюда айди роли!' }]}
                    >
                        <InputNumber min={0} value={nd.id} onChange={(value) => handleChange('id', value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Название (латиницей)"
                        name="name"
                        rules={[{ required: true, message: 'Пожалуйста, введи сюда название роли!' }]}
                    >
                        <Input value={nd.name} onChange={(e) => handleChange('name', e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Цвет"
                        name="color"
                        rules={[{ required: true, message: 'Пожалуйста, выбери цвет для роли!' }]}
                    >
                        <ColorPicker
                            value={nd.color}
                            showText
                            disabledAlpha
                            onChange={(value) => handleChange('color', value.toHexString())}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Админская ли роль?"
                        name="admin"
                        valuePropName="checked"
                    >
                        <Checkbox value={nd.access} onChange={(e) => handleChange('admin', e.target.checked)}/>
                    </Form.Item>
                    <Form.Item
                        label="Доступ"
                        name="access"
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Пожалуйста, выберите хотя бы одну"
                            value={nd.access}
                            onChange={(value) => handleChange('access', value)}
                            options={options}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Подтвердить(отправить)
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default RolesEditModal;
