import React, { useEffect, useState } from "react";
import { RoutesRouter } from "./router";
import { useTranslation } from 'react-i18next';
import { Button, Layout, Menu, MenuProps, } from 'antd';
import Footer from "../small-components/footer";

import scpublogo from "../images/scpublogo.png";

import "../css/canvas.css";
import "../css/app.css";

import { api } from "../const";
import { NavigationLink } from "../big-components/navbar/navlink";

const { Header, Content } = Layout;

type UserData = {
  nickname?: string;
  steamid?: string;
  avatar?: string;
  link?: string;
  roles?: any;
  reverse_id?: number;
  connect_time?: any;
  server_id?: number;
};


type AccessesType = {
  roles: boolean;
  rules: boolean;
  online: boolean;
  users: boolean;
};

type windowprps = {
  userData: any;
}

const MainWindow: React.FC<windowprps> = ({userData}) => {
  const { t } = useTranslation();
  const height = window.innerHeight - 80;
  let accessesarr = ["roles","rules","online","users"];
  const [items, setItems] = useState([{
    key: 0,
    label: <NavigationLink link="/" outLink={false} text="Профиль"/>
  },]);
  const [mount, setMount] = useState(false);
  const updates = {
    roles: false,
    rules: false,
    users: false,
    online: false,
  };

  useEffect(() => {
    fetchAccess(); // Инициализируем вызов данных при монтировании компонента
    /*const intervalId = setInterval(fetchAccess, 5000); // 1000 миллисекунд = 1 секунда

    return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента*/
  }, []);

  const fetchAccess = async () => {
    try {
      
      for (let i = 0; i < accessesarr.length; ++i) {
        const res = await api.get(`/api/admin/access?=${accessesarr[i]}`, { withCredentials: true });
        if (res.status === 200) {
          updates[accessesarr[i] as keyof AccessesType] = true;
        }
      }
      addingItems();
      setMount(false);
      setMount(true);
    } catch (err) {
      console.log(err);
    }
  }

  const addingItems = () => {
    let newItems = [...items]; // Создаем копию массива

    if (updates.online && !newItems.find(item => item.key === 1)) {
      newItems.push({ key: 1, label: <NavigationLink link="/online" outLink={false} text="Онлайн" /> });
    }
    if (updates.roles && !newItems.find(item => item.key === 2)) {
      newItems.push({ key: 2, label: <NavigationLink link="/roles" outLink={false} text="Роли" /> });
    }

    setItems(newItems);
  };

  return(
    <Layout style={{height: height}}>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="demo-logo" /><span></span>
        {mount && <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['0']}
          items={items}
          style={{ flex: 1, minWidth: 0 }}
        />}
      </Header>
      <Content style={{ padding: '0 48px', height: "100vh" }}>
        <div
          style={{
            height: "100%",
            padding: 24,
          }}
        >
          {updates && <RoutesRouter userData={userData} t={t} accesses={updates}/>}
        </div>
      </Content>
    </Layout>
  );
}

const App: React.FC = () => {
    const [userData, setUserData] = useState<UserData>({
      nickname: 'a',
      steamid: 'a',
      avatar: 'a',
      link: 'a',
      roles: { serverrole: 'a', siteroles: ['a'] },
      reverse_id: 0,
      server_id: 0
    });
    const height = window.innerHeight - 80;
    const [access, setAccess] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.get('/admin/', { withCredentials: true });
          if (response.data.nickname) {
            setUserData(response.data);
            setAccess(true);
          }
          
        } catch (err) {
          setAccess(false);
        }
      };
  
      fetchData(); // Инициализируем вызов данных при монтировании компонента
    }, []);

    return(
        <div className="app">
          {access ? (
            <MainWindow userData={userData}/>
          ):(
            <div style={{backgroundColor: 'rgb(40,40,40)', height: height, display: 'flex', justifyContent: 'center', verticalAlign: 'middle', alignItems: 'center', flexDirection: 'column'}}>
              <img width={256} src={scpublogo} alt="aboba"/>
              <h1 style={{verticalAlign: 'middle', textAlign: 'center', height: '50px', color:'white'}}>Войдите в систему:</h1>
              <Button href="https://scpubtroll.net/scpub/api/auth/steam/admin" type="text" style={{color: 'white'}}>Войти через стим</Button>
            </div>
          )}
          
          <Footer description="SC-Pub AdminSite. Версия 0.5.0, выполнил и сделал Андрей (Coelho_)"/>
        </div>
    );
}

export default App
