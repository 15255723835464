import React, { useEffect, useState } from "react";
import { Layout, Menu, MenuProps, theme } from 'antd';
import {
    UserOutlined,
    TeamOutlined,
    SettingOutlined,
} from '@ant-design/icons';

import { prps } from "./profile-page";
import { api } from "../const";
import {RolesAdmins, RolesUsers} from "./roles-components/roles-table";
import "../css/roles.css"
import RolesEdit from "./roles-components/roles-edit";

const { Content, Sider, Header } = Layout;

const RolesPage: React.FC<prps> = ({data}) => {
    const {token: { colorBgContainer, borderRadiusLG },} = theme.useToken();
    const [option, setOption] = useState(0);
    const [users, setUsers] = useState([]);
    const [text, setText] = useState('');
    const width = window.innerWidth;
    const height = window.innerHeight;

    const items: MenuProps['items'] = [
        {
          key: 0,
          label: <p onClick={() => setOption(0)}><TeamOutlined /> Изменение админов</p>
        },
        {
            key: 1,
            label: <p onClick={() => setOption(1)}><UserOutlined /> Изменение юзеров</p>
        },
        {
            key: 2,
            label: <p onClick={() => setOption(2)}><SettingOutlined /> Настройка ролей</p>
        },
    ];

    useEffect(()=>{
        fetchUsers();
    },[]);

    const fetchUsers = async () => {
        try{
            const resData = (await api.get('/api/users/get', {withCredentials: true})).data;
            setUsers(resData);
        }catch(err){
            console.log(err);
        }
    }

    useEffect(() => {
        switch (option) {
            case 0:
                setText('Изменение ролей у администраторов (повышение, понижение, добавление роли на сервере/сайт)');
                break;
            case 1:
                setText('Изменение ролей у пользователей (добавление ВИПа, добавление в администраторский состав)');
                break;
            case 2:
                setText('Настройка ролей (добавление/изменение/удаление)');
                break;
            default:
                setText('');
                break;
        }
    }, [option]);

    const СomponentsSwtich = (option: number) => {
        switch (option){
            case 0:
                return <RolesAdmins fetchUsers={fetchUsers} key={option} data={data} users={users}/>;
            case 1:
                return <RolesUsers fetchUsers={fetchUsers} key={option} data={data} users={users}/>;
            case 2:
                return <RolesEdit data={data}/>;
            default:
                return null;
        }
    }

    return(
        <>
        <Layout
            style={{ height: height - 200, borderRadius: borderRadiusLG }}
        >
            <Header style={{ display: 'flex', alignItems: 'center', borderTopLeftRadius: borderRadiusLG, borderTopRightRadius: borderRadiusLG, backgroundColor: "rgb(180,180,180)"}}>
                <img alt="avatar" src={data.avatar} style={{borderRadius: 100}}/>
                <h2 style={{marginLeft: 10, color: "white", textShadow: '1px 0 1px grey, 1px 1px 1px grey, 0 1px 1px grey, -1px 0 1px grey, 0 -1px 1px grey'}}>{data.nickname}</h2>
            </Header>
            <Layout style={{padding: '24px 0', background: colorBgContainer, borderBottomLeftRadius: borderRadiusLG, borderBottomRightRadius: borderRadiusLG}}>
                <Sider width={210}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={['0']}
                        style={{ height: '100%' }}
                        items={items}
                    />
                </Sider>
                <div className="content-of-profile" style={{width: "100%", height: '100%', display: 'flex', flexDirection: 'column'}}>
                  <h1 style={{textAlign: 'center', width: '85%', paddingBottom: '10px', margin: '0 auto', borderBottom: '1px rgb(240, 240, 240) solid'}}>{text}</h1>
                  <div style={{overflowY: 'auto'}}>
                    <Content style={{ padding: '0 24px' }}>{СomponentsSwtich(option)}</Content>
                  </div>
                </div>
            </Layout>
        </Layout>
        </>
    );
}

export default RolesPage;