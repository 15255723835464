import React, { useEffect } from "react";
import { Divider, Popover, Modal, Button } from 'antd';

import { api } from "../../const";

type data = {
    owner: string;
    driver: string;
    route: number;
    position: any;
    name: string;
    speed: number;
    bortnum: string;
}

type user = {
    nickname: string;
    steamid: string;
}

interface ProcessedTroll {
    owner: string;
    route: number;
    position: number[];
    name: string;
    speed: number;
    bortnum: string;
    ownernickname?: string;
    drivernickname?: string;
}

interface canvasData {
    data: any;
}
  
const TelemetryCanvas: React.FC<canvasData> = ({data}) => {
    const [newData, setNewData] = React.useState<ProcessedTroll[]>([]);
    const [open, setOpen] = React.useState(false);
    const scalingNumber = 16384;
    const size = 500;
    const trolls: data[] = data.trolls;
    const linksToMaps: any = {
        gm_sumy_reborn: 'https://cdn.discordapp.com/attachments/1150464439842263061/1260357882591838382/sumy_map.png?ex=668fafff&is=668e5e7f&hm=6c4f5a73caae75370279e778488a437b196de134d19e1a23ac7dd18342f2df1f&',
        gm_bobersk: 'https://cdn.discordapp.com/attachments/1151585079894741004/1262207337784021082/gm_bobersk2.png?ex=6695c1b0&is=66947030&hm=371e183dc2cfababf5fb98dcc550d920201ea3dc69be5ecb0704c45b71437423&',
        gm_york_remaster: 'https://media.discordapp.net/attachments/1022191249865850891/1262359501919621231/gm_york_remaster.png?ex=66964f66&is=6694fde6&hm=19987fca356d7b3044815b71b4f43b8fcb57f7312ab2ae103fe707da7f4be57e'
    }

    const link = linksToMaps[data.map];

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
    };
    
    const handleCancel = () => {
        setOpen(false);
    };

  
    const SplitPos = (data: string): number[] => {
      const spldata = data.replace('[', '').replace(']', '');
      return spldata.split(' ').map(parseFloat);
    };
  
    const getNickname = async (value: string): Promise<string | undefined> => {
      try {
        const link = '/api/profile/get?steamid=' + value;
        const { data } = await api.get(link);
        return data.nickname || 'Отсутствует';
      } catch (err) {
        console.log(err);
        return 'Отсутствует';
      }
    };
  
    useEffect(() => {
      const fetchNicknames = async () => {
        const processedData = await Promise.all(
          trolls.map(async (troll) => {
            const ownernickname = await getNickname(troll.owner);
            const drivernickname = await getNickname(troll.driver);
            return {
              owner: troll.owner,
              route: troll.route,
              position: SplitPos(troll.position),
              name: troll.name,
              speed: troll.speed,
              bortnum: troll.bortnum,
              ownernickname,drivernickname
            };
          })
        );
        setNewData(processedData);
      };
  
      fetchNicknames();
    }, [data]);

    function remapX (value: number)
    {
        return size/2 + (value / scalingNumber * size/2) -15
    }

    function remapY (value: number)
    {
        return size/2 - (value / scalingNumber * size/2) -9
    }

    return(
        <>
            <Button onClick={showModal}>Открыть сведения</Button>
            <Modal
                open={open}
                title={data.title}
                onOk={handleOk}
                onCancel={handleCancel}
                width={600}
            >

            <div id="canvas-test" style={{backgroundImage: 'url('+link+')', backgroundRepeat: 'no-repeat', backgroundSize: size, width: size, height: size, margin: 'auto', borderRadius: 5}}>
                    {newData.map((troll, index) => (
                        <Popover key={index} content={
                            <div>
                                <Divider style={{margin: 0}} type="horizontal"/>
                                <p style={{margin: 2}}><b>Водитель:</b> {troll.drivernickname}</p>
                                <p style={{margin: 2}}><b>Владелец:</b> {troll.ownernickname}</p>
                                <p style={{margin: 2}}><b>Маршрут:</b> {troll.route || troll.route === 0 && "В парк"}</p>
                                <p style={{margin: 2}}><b>Скорость:</b> {troll.speed || troll.speed === 0 && "Стоит"}</p>
                            </div>
                        } title={troll.bortnum}>
                            <div style={{
                                marginTop: remapY(troll.position[1]),
                                marginLeft: remapX(troll.position[0]),
                                position: 'fixed', zIndex: 10, backgroundColor: 'white', borderRadius: 5,
                                width: 30, height: 'auto', textAlign: 'center', border: '1px solid grey',
                                boxShadow: '0 1px 1px 0px rgba(0,100,255,0.5'}}>
                                <p style={{fontSize: 8, margin: 2,}}><b>{troll.bortnum}</b></p>
                                {/*<img key={index} width={10} height={10} src={vmz}></img>*/}
                                
                            </div>
                        </Popover>
                    ))}
                </div>
            </Modal>
        </>
    )
}

export default TelemetryCanvas;