import React from "react";
import { Route, Routes } from "react-router-dom";

import OnlinePage from "../pages/online-page";
import ProfilePage from "../pages/profile-page";
import RolesPage from "../pages/roles-page";


interface props {
    userData: any;
    t: any;
    accesses: any;
}

export const RoutesRouter: React.FC<props> = ({userData, t, accesses}) => {
    const [mount, setMount] = React.useState(false);
    React.useEffect(() => {
        const mounting = () => {
          if (accesses.online) {
            setMount(true);
          }
        };
        mounting(); // Выполнить один раз при монтировании компонента
        const intervalId = setInterval(mounting, 5000); // 5000 миллисекунд = 5 секунд
      
        return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
      }, [accesses, mount]);
    return(
        <>
        <Routes>
            <Route path="/" element={<ProfilePage data={userData}/>} />
            <Route path="/online" element={<OnlinePage data={userData}/>} />
            <Route path="/roles" element={<RolesPage data={userData}/>} />
        </Routes>
        </>
    );
}