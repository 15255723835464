import React, {useState, useEffect} from "react";
import { api } from "../../const";
import RolesModal from "./roles-modal";
import Search from "antd/es/input/Search";

interface data {
    data: any;
    users: any;
    fetchUsers: any;
}

interface table {
    users: any;
    data: any;
    fetchUsers: any;
}

interface si {
    users: any;
    sData: any;
}

const SearchInput: React.FC<si> = ({sData}) => {

    const onSearch = async (value: string) => {
        try{
            const link = '/api/profile/get?steamid='+value;
            const res = await api.get(link);
            const resData = res.data;

            const data = [resData];
    
            sData(data);
        } catch(err){
          console.error(err);
        }
    }

    return(
        <Search
            placeholder="steamid/id"
            allowClear
            onSearch={onSearch}
            style={{
                width: '70%',
                marginLeft: '15%',
                marginRight: '15%',
                marginBottom: '1%',
                marginTop: '1%',
            }}
        />
    );
};

const Table: React.FC<table> = ({users,fetchUsers,data,}) => {
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    const [roles, setRoles] = useState([{
        name: '',
        access: [''],
        id: 100,
        admin: false,
    }]);

    const fetchRoles = async () => {
        try {
            const resData = (await api.get('/api/admin/roles')).data;
            if (resData) {
                resData.sort((a:any, b:any) => a.id - b.id)
                setOptions(resData.map((role: any) => ({
                    value: role.name,
                    label: role.name,
                })));
                setRoles(resData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {fetchRoles();},[])

    return(
        <div className="roles-table-box table-box">
            <table className="roles-table admins-table table">
                <thead>
                    <tr>
                        <td><b>Никнейм</b></td>
                        <td><b>SteamID</b></td>
                        <td><b>Роль сервера</b></td>
                        <td><b>Роли сайта</b></td>
                        <td><b>Действия</b></td>
                    </tr>
                </thead>
                <tbody>
                    {roles.map((role) => 
                        (users.map((user: any, index: number)=>
                            (role.name === user.roles.serverrole  &&
                            <tr key={index}>
                                <td>{user.nickname}</td>
                                <td>{user.steamid}</td>
                                <td>{user.roles.serverrole}</td>
                                <td>{user.roles.siteroles.map((role: any, index: any, arr: any)=> {return index !== arr.length -1 ? role+', ' : role})}</td>
                                <td><RolesModal data={data} ad={user} updateData={fetchUsers} options={options}/></td>
                            </tr>
                            )
                        ))
                    )}
                    
                </tbody>
            </table>
        </div>
    );
};

const RolesAdmins: React.FC<data> = ({data,users,fetchUsers}) => {
    const [admins, sAdmins] = useState([]);

    useEffect(()=>{
        const newAdmins = users.filter((admin: any) => !admin.roles.serverrole.includes('user'));
        sAdmins(newAdmins);
    },[users]);

    return(
        <>
            <SearchInput users={admins} sData={sAdmins}/>
            <Table users={admins} data={data} fetchUsers={fetchUsers}/>
        </>
    )
};

const RolesUsers: React.FC<data> = ({data,users,fetchUsers}) => {
    const [players, sPlayers] = useState([]);

    useEffect(()=>{
        const newAdmins = users.filter((player: any) => player.roles.serverrole.includes('user'));
        sPlayers(newAdmins);
    },[users]);

    return(
        <>
            <SearchInput users={players} sData={sPlayers}/>
            <Table users={players} data={data} fetchUsers={fetchUsers}/>
        </>
    )
};

export {RolesAdmins, RolesUsers, Table};
