import React, { useEffect, useState } from "react";
import { prps } from "./profile-page";
import "../css/summary.css";
import { api } from "../const";
import { Layout, notification, theme, Collapse, CollapseProps, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";


type GroupedDataType = {
  [key: string]: { connect_time: number; server_id: number; summary: number }[];
};


const OnlinePage: React.FC<prps> = ({data}) => {
    const [adminData, setAdminData] = useState([]);
    const [apiNot, contextHolder] = notification.useNotification();
    const {token: { colorBgContainer, borderRadiusLG },} = theme.useToken();
    const height = window.innerHeight;
    const timeZone = 'Europe/Moscow';

    const openNotification = () => {
        apiNot['warning']({
        message: 'Нету данных',
        description:
            'При получении данных возникла ошибка. Обнови страницу, либо если ошибка повторяется - напиши Coelho_',
        });
    };

    function gettingDate(date: any){
      date = new Date(date * 1000);
      const dd = {
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          date: date.getDate(),
          time: date.getHours()+':'+(date.getMinutes() < 10 ? '0'+`${date.getMinutes()}` : date.getMinutes()),
      }
      return dd.date+'.'+dd.month+'.'+dd.year+', '+dd.time;
    }

    function gettingTime (time: any) {
      const newTime = new Date(time * 1000);

      const d = { // Creating data of summary time from last time, named D
          minutes: newTime.getMinutes(),
          seconds: newTime.getSeconds(),
      }
      return Math.floor(time / 3600) +' ч. '+d.minutes+' мин. '+(d.seconds < 10 ? '0'+`${d.seconds}` : d.seconds)+' с.'
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
              const res = (await api.get('/admin/online', { withCredentials: true })).data;
              if(res){
                res.sort((a:any, b:any) => (a.server_id - b.server_id))
                setAdminData(res);
              } else {
                openNotification();
              }
            } catch (err) {
              console.error(err);
              openNotification();
            }
        };
        fetchData(); // Инициализируем вызов данных при монтировании компонента
    },[]);

    const OnlineData = (admin: any) => {
      const [groupedData, setGroupedData] = useState<GroupedDataType>({});

      // Группировка данных по годам
      useEffect(() => {
        const groupByYear = (data: any[]) => {
          return data.reduce((acc: { [key: string]: any[] }, item) => {
            const date = new Date(item.connect_time * 1000);
            const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`; // Формат "Год-Месяц"
        
            if (!acc[yearMonth]) {
              acc[yearMonth] = [];
            }
        
            acc[yearMonth].push(item);
            return acc;
          }, {});
        };
        
        if(admin.admin && admin.admin.connect_time){
          setGroupedData(groupByYear(admin.admin.connect_time));
        }
      }, []);

      const items: CollapseProps['items'] = Object.keys(groupedData)
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime()) // Сортировка по убыванию дат (год-месяц)
        .map((yearMonth: string, index: number) => {
          return {
            key: index,
            label: (
              <div style={{ display: 'flex', width: '20%' }}>
                <h4 style={{ margin: '5px 10px 5px 10px' }}>{yearMonth}</h4>
                <h4 style={{ margin: '5px 10px 5px 10px' }}>{`${Math.floor(groupedData[yearMonth].reduce((summary: number, item) => ((summary + item.summary)), 0) / 60 / 60)} ч. и ${groupedData[yearMonth].length} раз заходил(-а)`}</h4>
              </div>
            ),
            children: (
              <div className="online-table-box">
                <table className="online-table" >
                  <thead>
                    <tr>
                      <td><h3>Время захода</h3></td>
                      <td><h3>Сервер, на который заходил</h3></td>
                      <td><h3>Суммарное проведённое время</h3></td>
                      <td><h3>Время выхода</h3></td>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedData[yearMonth] ? 
                      groupedData[yearMonth].reverse().map((row: any, idx: number, arr: any) => 
                        <tr key={idx}>
                          <td>
                            <Tooltip title={`${new Date((row.connect_time) * 1000).toLocaleString('ru-RU', {
                                timeZone: timeZone,
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: false
                              })}`}>
                              {`${gettingDate(row.connect_time)}`}
                            </Tooltip>
                          </td>
                          <td className="column">{row.server_id}</td>
                          <td className="column">{`${gettingTime(row.summary)}`}</td>
                          <td>
                            <Tooltip title={`${new Date((row.connect_time + row.summary) * 1000).toLocaleString('ru-RU', {
                              timeZone: timeZone,
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                              hour12: false
                            })}`}>
                              {row.server_id === data.server_id && arr.length - 1 === idx ? 'На сервере' : `${gettingDate(row.connect_time + row.summary)}`}
                            </Tooltip>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>Нету данных</td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            )
          };
        });

      
      return(
        <Collapse items={items} />
      )
    }

    const items: CollapseProps['items'] = adminData.map((admin: any, index: number) => ({
      key: index,
      label: <div style={{display: 'flex', width: '20%',}}><img alt="avatar" style={{margin: '5px 10px 5px 10px', width: 20, height: 20, borderRadius: 20}} src={admin.avatar}/><p style={{margin: '5px 10px 5px 10px',}}>{admin.nickname}</p></div>,
      children: <OnlineData admin={admin}/>,
    }));

    return(
        <>
        {contextHolder}
        <Layout style={{ height: height - 200, borderRadius: borderRadiusLG }}>
          <Layout style={{padding: '24px', background: colorBgContainer, borderRadius: borderRadiusLG}}>
            <h1>Учёт онлайна администрации</h1>
            <Content style={{height: height - 210, overflow: 'auto', marginTop: 10}}>
              <Collapse items={items}/>
            </Content>
          </Layout>
        </Layout>
        </>
    );
}

export default OnlinePage;
