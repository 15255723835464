import React, { useEffect, useState } from "react";
import { api } from "../../const";
import "../../css/summary.css";
import TelemetryCanvas from "./telemetry";

export interface SummaryProps {
    osbd?: any;
    os?: any;
    s?: number;
    width?: number;
    fs?: any;
}

/*
ospd – own summary by day
os – full own summary in hours
s – server;
fs – full summary
*/

const SummaryHoursPerLastEnter: React.FC<SummaryProps> = ({osbd, s}) => {
    const date = new Date(osbd.summary * 1000);

    const d = { // Creating data of summary time from last time, named D
        hours: Math.floor(osbd.summary / 3600),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
    }

    const SummaryTime = () => {
        return(
            <p style={{fontSize: 20}}><b>{d.hours} ч. {d.minutes} мин. {d.seconds} с.</b></p>
        );
    }

    return(
        <div className="summary-card">
            <h4>Время проведенное на сервере<br/>за последний сеанс:</h4>
            <SummaryTime />
            <h4>На {s}-м сервере</h4>
        </div>
    );
}

const SummaryHours: React.FC<SummaryProps> = ({os}) => {
    const date = new Date(os * 1000);

    const d = { // Creating data of summary time from last time, named D
        days: Math.floor(os / 3600 / 24),
        hours: Math.floor(os / 3600) > 24 ? Math.floor(os / 3600) - 24 * Math.floor(os / 3600 / 24) : Math.floor(os / 3600),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
    }

    const SummaryTime = () => {
        return(
            <p style={{fontSize: 20}}><b>{d.days} дней, {d.hours} ч. и {d.minutes} мин.</b></p>
        );
    }

    return(
        <div className="summary-card">
            <h4>Всего количество часов<br/>игры на проекте:</h4>
                <SummaryTime />
            <h4>Вау!</h4>
        </div>
    );
}

const WholeSummary: React.FC<SummaryProps> = ({osbd, os, s, width,}) => {
    const [servers, setServers] = useState([]);

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 5000); // 1000 миллисекунд = 1 секунда

        return () => clearInterval(intervalId);
    }, []);

    const fetchData = async() => {
        try {
            const res = await api.get('/api/public/servers/');
            const users = res.data.filteredUsers;
            const serverData: any = {};
            users.forEach((user: any) => {
                if (user.server_id) {
                    if (!serverData[user.server_id]) {
                        serverData[user.server_id] = [];
                    }
                    serverData[user.server_id].push(user);
                }
            });

            const resData = res.data.filteredServers.sort((a:any, b:any) => (a.server_id - b.server_id)).map((server:any)=>({
                server_id: server.server_id,
                map: server.map,
                status: server.status === 1 ? 'Включен' : 'Включен',
                link: server.link,
                title: server.title,
                maxplayers: server.maxplayers | 0,
                usersCount: serverData[server.server_id] ? serverData[server.server_id].length : 0,
                users: serverData[server.server_id] ? serverData : 0,
                trolls: server.trolls,
            }));
            setServers(resData);
        } catch (error) {
            console.log(error)
        }
    }
    return(
        <div>
            <div className="summary-content" style={width ? width > 800 ? {display: "flex"} : {display: "block", padding: "2%"} : {}}>
                <SummaryHoursPerLastEnter osbd={osbd} s={s}/>
                <SummaryHours os={os}/>
            </div>
            <div className="table-box servers" style={width ? width > 800 ? {display: "flex"} : {display: "block", padding: "2%"} : {}}>
                <table className="table">
                    <thead>
                        <tr>
                            <td>Серверный айди</td>
                            <td>Количество игроков</td>
                            <td>Количество троллов</td>
                            <td>Действия</td>
                        </tr>
                    </thead>
                    <tbody>

                    </tbody>
                        {servers.map((srvr: any, index: number) => (
                            <tr>
                                <td>{srvr.server_id}</td>
                                <td>{srvr.userCount || 0}</td>
                                <td>{srvr.trolls.length}</td>
                                <TelemetryCanvas data={srvr} key={index}/>
                            </tr>
                        ))}
                </table>
            </div>
            
        </div>
    );
}

export {WholeSummary};
