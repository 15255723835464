import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Drawer, Layout, Menu, MenuProps, theme } from 'antd';
import {WholeSummary} from "./profile-components/summary";
import { useTranslation } from "react-i18next";
import {
  BarChartOutlined,
  TableOutlined,
} from '@ant-design/icons';
import {OnlineTable} from "./profile-components/online-table";


const { Content, Sider, Header } = Layout;

export interface prps {
  data: any;
}

const ProfilePage: React.FC<prps> = ({data}) => {
    const [open, setOpen] = useState(false);
    const [option, setOption] = useState(0);
    const [text, setText] = useState('');
    const [fullSummary, setFullSummary] = useState(0);
    const {token: { colorBgContainer, borderRadiusLG },} = theme.useToken();
    const width = window.innerWidth;
    const height = window.innerHeight;
    const {t} = useTranslation();

    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      if (data.connect_time && data.connect_time.length > 0) {
        const totalSummary = data.connect_time.reduce((sum: any, ct: any) => sum + ct.summary, 0);
        setFullSummary(totalSummary);
      }
    }, [data]);

    const items: MenuProps['items'] = [
      {
        key: 0,
        label: <p onClick={() => setOption(0)}><BarChartOutlined /> Общая информация</p>
      },
      {
        key: 1,
        label: <p onClick={() => setOption(1)}><TableOutlined /> Таблица онлайна</p>
      }
    ]

    useEffect(() => {
      switch (option) {
        case 0:
          setText('Общая информация о себе и статистика');
          break;
        case 1:
          setText('Таблица онлайна (входа на сервер, суммарное проведённое время на нём)');
          break;
        default:
          setText('');
      }
    }, [option]);

    const СomponentsSwtich = (option: number) => {
      if(data.connect_time.length !== 0){
        switch (option){
          case 0:
            return (<WholeSummary osbd={data.connect_time[data.connect_time.length - 1]} os={fullSummary} s={data.connect_time[data.connect_time.length - 1].server_id} width={width}/>)
          case 1:
            const dtOfFS = {
              data: data.connect_time,
              server_id: data.server_id
            }
            return (<OnlineTable fs={dtOfFS}/>)
        }
      } else {
        return <h1>Вы не заходили на сервер с момента добавления Вас в базу / обновления системы</h1>
      }
    }

    return(
        <>
        {data.roles ? (
          width > 800 ? (
            <Layout
              style={{ height: height - 200, borderRadius: borderRadiusLG }}
            >
              <Header style={{ display: 'flex', alignItems: 'center', borderTopLeftRadius: borderRadiusLG, borderTopRightRadius: borderRadiusLG, backgroundColor: "rgb(180,180,180)"}}>
                <img alt="avatar" src={data.avatar} style={{borderRadius: 100}}/>
                <h2 style={{marginLeft: 10, color: "white", textShadow: '1px 0 1px grey, 1px 1px 1px grey, 0 1px 1px grey, -1px 0 1px grey, 0 -1px 1px grey'}}>{data.nickname}</h2>
                <h3 style={{marginLeft: 50, color: "white", textShadow: '1px 0 1px grey, 1px 1px 1px grey, 0 1px 1px grey, -1px 0 1px grey, 0 -1px 1px grey'}}>{t("roles."+data.roles.serverrole)}</h3>
              </Header>
              <Layout style={{padding: '24px 0', background: colorBgContainer}}>
                <Sider width={200}>
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={['0']}
                    style={{ height: '100%' }}
                    items={items}
                  />
                </Sider>
                <div className="content-of-profile" style={{width: "100%", height: '100%', display: 'flex', flexDirection: 'column'}}>
                  <h1 style={{textAlign: 'center', width: '85%', paddingBottom: '10px', margin: '0 auto', borderBottom: '1px rgb(240, 240, 240) solid'}}>{text}</h1>
                  <div style={{overflowY: 'auto'}}>
                    <Content style={{ padding: '0 24px' }}>{СomponentsSwtich(option)}</Content>
                  </div>
                </div>
              </Layout>
            </Layout>
          ):(
            <div>
                <h1 style={{textAlign: 'center', width: '85%', paddingBottom: '10px', margin: 'auto', borderBottom: '1px rgb(240, 240, 240) solid'}}>{text}</h1>
                {СomponentsSwtich(option)}
                <Button onClick={showDrawer}>Открыть меню</Button>
                <Drawer
                  title="Меню с кнопачгами"
                  placement="bottom"
                  closable={true}
                  onClose={onClose}
                  open={open}
                >
                  <Menu
                    mode="inline"
                    style={{width: "100%"}}
                    items={items}
                  />
                </Drawer>
            </div>
          )
        ):(
          <div></div>
        )}
          
        </>
    );
}

export default ProfilePage;