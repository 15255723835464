import React, { useEffect } from "react";
import { api } from "../../const";
import RolesEditModal from "./roles-edit-modal";
import type {PopconfirmProps} from 'antd';
import {Popconfirm, Button, message} from "antd";

interface prps {
    data: any;
}

const RolesEdit: React.FC<prps> = ({data}) => {
    const [roles, sRoles] = React.useState([]);
    const [accesses, sAccesses] = React.useState<{ value: string; label: string }[]>([]);

    const fetchRoles = async () => {
        try {
            const resData = (await api.get('/api/admin/roles')).data;
            if (resData) {
                resData.sort((a:any, b:any) => a.id - b.id)
                sRoles(resData);
            }
            const resDataAcs = (await api.get('/api/admin/accesses')).data;
            if (resDataAcs) {
                sAccesses(resDataAcs.map((access: string) => ({
                    value: access,
                    label: access,
                })));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const confirm: PopconfirmProps['onConfirm'] = async (id) => {
        await api.post('/api/admin/roles/delete', {id}, { withCredentials: true });
        fetchRoles();
    };
      
    const cancel: PopconfirmProps['onCancel'] = (e) => {
        return;
    };

    useEffect(() => {
        fetchRoles();
    },[]);

    return(
        <div className="table-box">
            <div className="roles-table-buttons-panel">
                <RolesEditModal updateData={fetchRoles} options={accesses} newrole/>
            </div>
            <table className="table admins-table">
                <thead>
                    <tr>
                        <td><b>ID</b></td>
                        <td><b>Название</b></td>
                        <td><b>Админская роль?</b></td>
                        <td><b>Права роли</b></td>
                        <td><b>Действия</b></td>
                    </tr>
                </thead>
                <tbody>
                    {roles.map((r: any, index: number)=>(
                        <tr style={{backgroundColor: r.color, color: 'white'}} key={index}>
                            <td><b>{r.id}</b></td>
                            <td><b>{r.name}</b></td>
                            <td><b>{r.admin ? "Да" : "Нет"}</b></td>
                            <td><b>{r.access.map((access: string, index: number, arr: any) => {return index !== arr.length-1 ? access+", " : access})}</b></td>
                            <td>
                                <RolesEditModal updateData={fetchRoles} options={accesses} newrole={false} rd={r}/>
                                <Popconfirm
                                    title="Удаление роли"
                                    description="Ты уверен, что хочешь удалить эту роль?"
                                    onConfirm={() => confirm(r.id)}
                                    onCancel={cancel}
                                    okText="Да"
                                    cancelText="Нет"
                                ><Button danger>Х</Button></Popconfirm>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
} 

export default RolesEdit;
