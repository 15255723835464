import axios from "axios";
import config from "./config.json";

export const url = config.cfg.local ? config.links.locallink : config.links.publiclink;

export const api = axios.create({
    baseURL: url+'/', // change to your desired hostname and port
    headers: {
      'Content-Type': 'application/json'
    }
});

export interface t {
  tr: any;
}